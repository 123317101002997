import { FeatureItem } from "../types/interface";

export const features: FeatureItem[] = [
    {
      title: "Years of Expertise",
      description:
        "Our quants bring years of in-depth expertise across diverse industries and tech sectors.",
    },
    {
      title: "Proven Track Record",
      description:
        "With a track record of successful project delivery within budget and deadlines, we're the reliable technology partner you need.",
    },
    {
      title: "Continuous Innovation",
      description:
        "Innovation is our ethos. We're always at the forefront, integrating the newest approaches to drive value and foster growth for our clients.",
    },
  ];