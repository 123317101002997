import React, { InputHTMLAttributes } from "react";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  id: string;
}

const CustomInput: React.FC<InputProps> = ({ label, id, ...props }) => {
  return (
    <div className="w-full flex flex-col">
      <label
        className="text-white text-base font-medium leading-normal sm:text-sm"
        htmlFor={id}
      >
        {label}
      </label>
      <input
        id={id}
        className="w-full p-4 border-b border-white rounded-none bg-transparent text-gray-300 text-lg font-medium leading-relaxed focus:outline-none"
        {...props}
      />
    </div>
  );
};

export default CustomInput;
