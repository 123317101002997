import React, { useRef, useEffect } from "react";
import { ServiceDetails } from "./ServiceDetails";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import useWindowDimensions from "../../Hooks/ScreenDimensionsHook";

export const Services: React.FC = () => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const width = useWindowDimensions();
  const isMobile = width < 1440;
  useGSAP(() => {
    if (sectionRef.current) {
      gsap.fromTo(
        ".service",
        {
          opacity: 0,
          x: -550,
        },
        {
          opacity: 1,
          x: 0,
          duration: 1,
          scrollTrigger: {
            trigger: sectionRef.current,
            start: "top 100%",
            end: "bottom 90%",
            scrub: true,
          },
        }
      );
    }
    gsap.fromTo(
      ".service-details",
      {
        scale: 0,
        opacity: 0,
      },
      {
        scale: 1,
        opacity: 1,
        duration: 1,
        scrollTrigger: {
          trigger: sectionRef.current,
          start: "top 80%",
          end: "bottom 80%",
          scrub: true,
        },
      }
    );
  }, []);

  useEffect(() => {
    const circles = document.querySelectorAll(".service-details");

    circles.forEach((circle) => {
      circle.addEventListener("mouseenter", () => {
        gsap.to(circle, { scale: 1.1, ease: "power1.inOut", duration: 0.3 });
        gsap.to(
          Array.from(circles).filter((c) => c !== circle),
          { scale: 0.9, ease: "power1.inOut", duration: 0.3 }
        );
      });
      circle.addEventListener("mouseleave", () => {
        gsap.to(circle, { scale: 1, ease: "power1.inOut", duration: 0.3 });
        gsap.to(
          Array.from(circles).filter((c) => c !== circle),
          { scale: 1, ease: "power1.inOut", duration: 0.3 }
        );
      });
    });

    return () => {
      circles.forEach((circle) => {
        circle.removeEventListener("mouseenter", () => {
          gsap.to(circle, { scale: 1.1, ease: "power1.inOut", duration: 0.3 });
          gsap.to(
            Array.from(circles).filter((c) => c !== circle),
            { scale: 0.9, ease: "power1.inOut", duration: 0.3 }
          );
        });
        circle.removeEventListener("mouseleave", () => {
          gsap.to(circle, { scale: 1, ease: "power1.inOut", duration: 0.3 });
          gsap.to(
            Array.from(circles).filter((c) => c !== circle),
            { scale: 1, ease: "power1.inOut", duration: 0.3 }
          );
        });
      });
    };
  }, []);

  return (
    <div
      ref={sectionRef}
      className="h-[100%] flex-row gap-5 md:flex-col justify-start items-center inline-flex md:justify-start"
    >
      <div className="relative h-auto flex-col justify-center items-center w-[35%] md:w-[100%] md:items-start service">
        <div className="text-white text-5xl font-bold leading-10 pb-6 sm:pb-3 sm:text-3xl">
          Services
        </div>
        <div className="text-zinc-400 text-xl font-normal 2xl:text-lg sm:text-sm leading-[28px]">
          QuantLabs provides end-to-end technology solutions. Whether you're
          looking to build a new product, optimize your existing systems, or
          improve your tech stack, we have the team and experience to help you
          succeed.
        </div>
        <div className="sm:hidden md:hidden w-96 h-96 absolute right-[280px] bottom-[280px] bg-gradient-to-br from-stroukBlue to-darkSky2 rounded-full blur-3xl" />
      </div>
      <div className="relative">
        <div className="absolute left-[200px] bottom-[-120px] md:left-[-100px] md:top-[40px]">
          <div className="flex bottom-0 w-[400px] h-[400px] md:w-[215px] md:h-[215px] transform rounded-full bg-gradient-to-b from-transparent via-[rgba(2.90,131.12,222.33,0.14)] to-[rgba(10.35,85.27,138.56,0.80)] service-details">
            <ServiceDetails
              title="Consultancy"
              description="Our advisory services help projects improve their tech infrastructure, fueling their growth and ensuring businesses stay ahead and outperform competitors"
            />
          </div>
        </div>
        <div
          className={`absolute left-[370px] md:left-[-20px] ${
            !isMobile && "hover:left-80"
          } md duration-500 md:top-[200px]`}
        >
          <div className="flex w-[400px] h-[400px] md:w-[215px] md:h-[215px] transform rotate-105deg origin-top-left bg-gradient-to-l from-transparent via-[rgba(2.90,131.12,222.33,0.14)] to-[rgba(10.35,85.27,138.56,0.80)] rounded-full service-details">
            <ServiceDetails
              title="Integration"
              description="Integrate your current infrastructure with our tech stack, enhancing its interoperability with various enterprise-grade technologies"
            />
          </div>
        </div>
        <div
          className={`absolute md:left-[-200px] md:top-[200px] ${
            isMobile ? "" : "2xl:left-[25px]"
          } left-[30px]`}
        >
          <div className="flex w-[400px] h-[400px] md:w-[215px] md:h-[215px] transform rotate-105deg origin-top-left bg-gradient-to-r from-transparent via-[rgba(2.90,131.12,222.33,0.14)] to-[rgba(10.35,85.27,138.56,0.80)] rounded-full service-details">
            <ServiceDetails
              title="Technology Provider"
              description="From custom software development to off-the-shelf solutions, our team of skilled developers and engineers will leverage the latest technologies to build robust, scalable, and future-proof solutions that align with your business goals"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
