import UserPhoto from "../assets/Image/UserPhoto1.png";

export const clientsInformation = [
  {
    image: UserPhoto,
    description:
      "“Thet team successfully developed my last two product launches. They were highly scalable, handling high amount of users with ease. Both the front-end and back-end infrastructure were delivered to the highest standards”",
    name: "Stephen Morgan",
    position: "Social Traders Tools",
  },
  {
    image: UserPhoto,
    description:
      "“Thet team successfully developed my last two product launches. They were highly scalable, handling high amount of users with ease. Both the front-end and back-end infrastructure were delivered to the highest standards”",
    name: "Stephen Morgan",
    position: "Social Traders Tools",
  },
  {
    image: UserPhoto,
    description:
      "“Thet team successfully developed my last two product launches. They were highly scalable, handling high amount of users with ease. Both the front-end and back-end infrastructure were delivered to the highest standards”",
    name: "Stephen Morgan",
    position: "Social Traders Tools",
  },
  {
    image: UserPhoto,
    description:
      "“Thet team successfully developed my last two product launches. They were highly scalable, handling high amount of users with ease. Both the front-end and back-end infrastructure were delivered to the highest standards”",
    name: "Stephen Morgan",
    position: "Social Traders Tools",
  },
  {
    image: UserPhoto,
    description:
      "“Thet team successfully developed my last two product launches. They were highly scalable, handling high amount of users with ease. Both the front-end and back-end infrastructure were delivered to the highest standards”",
    name: "Stephen Morgan",
    position: "Social Traders Tools",
  },
  {
    image: UserPhoto,
    description:
      "“Thet team successfully developed my last two product launches. They were highly scalable, handling high amount of users with ease. Both the front-end and back-end infrastructure were delivered to the highest standards”",
    name: "Stephen Morgan",
    position: "Social Traders Tools",
  },
  {
    image: UserPhoto,
    description:
      "“Thet team successfully developed my last two product launches. They were highly scalable, handling high amount of users with ease. Both the front-end and back-end infrastructure were delivered to the highest standards”",
    name: "Stephen Morgan",
    position: "Social Traders Tools",
  },
  {
    image: UserPhoto,
    description:
      "“Thet team successfully developed my last two product launches. They were highly scalable, handling high amount of users with ease. Both the front-end and back-end infrastructure were delivered to the highest standards”",
    name: "Stephen Morgan",
    position: "Social Traders Tools",
  },
];
