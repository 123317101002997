import React from "react";

interface CustomImageProps {
  src: string;
  alt: string;
  className?: string;
  responsive?: boolean;
}

export const CustomImage: React.FC<CustomImageProps> = ({
  src,
  alt,
  className = "",
  responsive = true,
}) => {
  const defaultStyles = responsive ? "h-auto" : "";

  return (
    <img src={src} alt={alt} className={`${defaultStyles} ${className}`} />
  );
};
