const Email = (props: any) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="email">
      <path
        id="Vector"
        d="M6.99976 9L11.9998 12.5L16.9998 9"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_2"
        d="M2.00024 17V7C2.00024 5.89543 2.89567 5 4.00024 5H20.0002C21.1048 5 22.0002 5.89543 22.0002 7V17C22.0002 18.1046 21.1048 19 20.0002 19H4.00024C2.89567 19 2.00024 18.1046 2.00024 17Z"
        stroke="white"
      />
    </g>
  </svg>
);
export default Email;
