import React, { useState } from "react";
import { Link } from "react-scroll";
// import Logo from '../../assets/Icons/Logo';
import blueLogo from "../../assets/Icons/quantLabsLogo-blue.svg";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import useWindowDimensions from "../../Hooks/ScreenDimensionsHook";

export const Header: React.FC = () => {
  const width = useWindowDimensions();
  const isMobile = width < 768;
  const [isNavOpen, setIsNavOpen] = useState(false);

  useGSAP(() => {
    gsap.fromTo(
      ".mobileNav",
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.5,
      }
    );
  }, [isNavOpen]);

  return (
    <header className="flex-grow overflow-hidden relative z-10">
      <div className="p-8 px-28 md:px-4 relative flex justify-between items-center h-8 max-w-[1600px] mx-auto  ">
        <div className="sm:hidden md:hidden w-96 h-96 absolute right-[-120px] top-[-150px] bg-gradient-to-br from-darkSky to-darkSky2 rounded-full blur-3xl z-[1]" />
        <div className="flex-shrink-0">
          <img
            src={blueLogo}
            alt="QuantLabs logo"
            className={`${isMobile ? "w-[10em]" : "w-[15em]"}`}
          />
          {/* <Logo /> */}
        </div>
        <div className="md:hidden flex space-x-4">
          <nav className="flex space-x-12 text-center z-10">
            <Link
              to="about"
              smooth={true}
              duration={500}
              className="cursor-pointer transition-colors duration-200 font-manrope text-base leading-6 text-white"
            >
              About Us
            </Link>
            <Link
              to="services"
              smooth={true}
              duration={500}
              className="cursor-pointer transition-colors duration-200 font-manrope text-base leading-6 text-white"
            >
              Services
            </Link>
            <Link
              to="contact"
              smooth={true}
              duration={500}
              className="cursor-pointer transition-colors duration-200 font-manrope text-base leading-6 text-white"
            >
              Contact Us
            </Link>
          </nav>
        </div>

        <div className="hidden md:flex items-center">
          <div
            className="HAMBURGER-ICON space-y-2"
            onClick={() => setIsNavOpen((prev) => !prev)}
          >
            <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
            <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
            <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
          </div>
        </div>

        <div
          className={`${
            isNavOpen ? "showMenuNav" : "hideMenuNav"
          } fixed inset-0 flex flex-col justify-evenly items-center z-10 bg-[#01060f] mobileNav`}
        >
          <div
            className="absolute top-0 right-0 px-8 py-8"
            onClick={() => setIsNavOpen(false)}
          >
            <svg
              className="h-8 w-8 text-gray-600"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="18" y1="6" x2="6" y2="18" />
              <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
          </div>
          <ul className="flex flex-col items-center justify-between min-h-[250px] pt-14">
            <li className="border-b border-gray-400 my-8 uppercase">
              <Link
                to="about"
                smooth={true}
                duration={500}
                className="cursor-pointer transition-colors duration-200 font-manrope text-base leading-6 text-gray-200"
                onClick={() => setIsNavOpen(false)}
              >
                About Us
              </Link>
            </li>
            <li className="border-b border-gray-400 my-8 uppercase">
              <Link
                to="services"
                smooth={true}
                duration={500}
                className="cursor-pointer transition-colors duration-200 font-manrope text-base leading-6 text-gray-200"
                onClick={() => setIsNavOpen(false)}
              >
                Services
              </Link>
            </li>
            <li className="border-b border-gray-400 my-8 uppercase">
              <Link
                to="contact"
                smooth={true}
                duration={500}
                className="cursor-pointer transition-colors duration-200 font-manrope text-base leading-6 text-gray-200"
                onClick={() => setIsNavOpen(false)}
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </div>

        <style>{`
        .hideMenuNav {
          display: none;
        }
        .showMenuNav {
          display: block;
        }
      `}</style>
      </div>
    </header>
  );
};
