import React from 'react';
import { InformationCard } from './InformationCard';

export const InformationSection: React.FC = () => {
  const infoCards = [
    { title: '6+', description: 'Years of Experience' },
    { title: '340+', description: 'Successful Projects' },
    { title: '210+', description: 'Satisfied Customers' },
    { title: '97%', description: 'Positive Reviews' },
  ];

  return (
    <div className='w-full h-auto flex sm:flex-col md:flex-row justify-center items-center gap-2 sm:gap-0 z-10 space-x-14 lg:space-x-0 md:space-x-0'>
      <div className='flex md:flex-col flex-row justify-center items-center gap-2 sm:gap-0 z-1 space-x-14 lg:space-x-6 md:space-x-0'>
        {infoCards.map((card, index) => (
          <React.Fragment key={index}>
            <InformationCard
              title={card.title}
              description={card.description}
            />
            {index < 3 && (
              <div className='md:w-[100%] w-[1px] md:h-[1px] h-20 bg-gray-300' />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default InformationSection;
