import React from "react";

interface CardProps {
  title: string;
  description: string;
  iconNumber?: number;
}

export const WhyUsCard: React.FC<CardProps> = ({
  title,
  description,
  iconNumber,
}) => {
  return (
    <div className="w-full p-10 sm:p-8 min-h-[200px] rounded-3xl border border-tealBlue backdrop-blur-md flex groww flex-col justify-start items-center hover:bg-gradient-to-tl from-[#073A63] via-[rgba(34,112,174,0.52)] to-[rgba(36,120,193,0.05)] ">
      {iconNumber && (
        <div className=" absolute py-2 px-3 left-0 top-[-20px] bg-blue-500 rounded-full justify-center items-center flex">
          <div className=" w-6 text-center text-white text-xl font-medium  leading-normal">
            {iconNumber}
          </div>
        </div>
      )}
      <div className=" h-auto  w-full flex flex-col justify-start items-start gap-4">
        <div className=" text-white text-2xl font-medium ">{title}</div>
        <div className=" text-zinc-400 text-base font-normal  leading-normal">
          {description}
        </div>
      </div>
    </div>
  );
};
