import React, { useRef } from "react";
import { FeatureItem } from "../../types/interface";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import useWindowDimensions from "../../Hooks/ScreenDimensionsHook";

gsap.registerPlugin(ScrollTrigger);

interface OurEdgeProps {
  features: FeatureItem[];
}

export const OurEdge: React.FC<OurEdgeProps> = ({ features }) => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const width = useWindowDimensions();
  const isMobile = width < 1440;
  useGSAP(() => {
    gsap.fromTo(
      ".animate-heading",
      {
        opacity: 0,
        y: -200,
      },
      {
        opacity: 1,
        y: 0,
        duration: 3,
        stagger: 0.3,
        ease: "power3.out",
        scrollTrigger: {
          trigger: sectionRef.current,
          start: "top 100%",
          end: "bottom 50%",
          scrub: true,
        },
      }
    );

    gsap.fromTo(
      ".animate-point",
      {
        opacity: 0,
        x: 200,
      },
      {
        opacity: 1,
        x: 0,
        duration: 1,
        stagger: 1,
        // ease: 'power3.out',
        scrollTrigger: {
          trigger: sectionRef.current,
          start: "top 100%",
          end: "bottom 80%",
          scrub: true,
        },
      }
    );
  }, []);

  return (
    <div
      className={`w-full h-auto flex lg:flex-col flex-row ${
        isMobile ? "items-start" : "items-center"
      } justify-between relative`}
      ref={sectionRef}
    >
      <div
        className={`${
          isMobile && "ml-[21px]"
        } animate-heading text-white text-5xl sm:text-[32px] font-bold leading-10 mb-4 md:mb-0 sm:pb-6`}
      >
        Our Edge
      </div>
      <div className="flex flex-col md:flex-row justify-start items-start gap-5 w-1/2 lg:w-full">
        <div className="flex flex-col justify-start items-start gap-8">
          {features.map((feature, index) => (
            <div
              key={index}
              className="flex flex-col text-[32px] md:text-xl text-white hover:text-sky-500 justify-start items-start gap-4 border-l-4 border-transparent hover:border-l-4 hover:border-sky-500 hover:rounded-s-sm hover:rounded-f-e rounded-e-sm pl-5 opacity-0 animate-point"
            >
              {feature.title}
              <div className="text-zinc-400 text-xl font-normal md:text-base leading-normal">
                {feature.description}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="sm:hidden md:hidden w-96 h-96 absolute right-[-280px] bottom-[-340px] bg-gradient-to-br from-stroukBlue to-darkSky2 rounded-full blur-3xl" />
    </div>
  );
};
