import React, { useRef, useState } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { useGSAP } from '@gsap/react';

gsap.registerPlugin(ScrollTrigger);

interface InformationCardProps {
  title: string;
  description: string;
}

export const InformationCard: React.FC<InformationCardProps> = ({
  title,
  description,
}) => {
  const [number, setNumber] = useState<number | null>(null);
  const numberRef = useRef<HTMLDivElement>(null);

  useGSAP(() => {
    const extractedNumber = parseInt(title.replace(/\D/g, ''), 10);

    const animation = gsap.fromTo(
      numberRef.current,
      {
        textContent: 0,
      },
      {
        textContent: extractedNumber,
        duration: 2,
        snap: { textContent: 1 },
        ease: 'power1.inOut',
        paused: true,
        onComplete: () => {
          setNumber(extractedNumber);
        },
      }
    );

    ScrollTrigger.create({
      trigger: numberRef.current,
      start: 'top 95%',
      end: 'top 80%',
      onEnter: () => {
        animation.play();
      },
      onLeaveBack: () => {
        animation.reverse();
      },
    });

    return () => {
      animation.kill();
    };
  }, [title]);

  return (
    <div className='w-auto md:w-auto h-auto flex flex-col justify-start items-start gap-4 sm:px-4 p-4 sm:p-6 md:p-8 '>
      <div className='flex justify-center self-stretch text-center text-blue-500 text-4xl font-bold leading-10'>
        <p ref={numberRef}>{number !== null ? number : title}</p>
        <p>{title[title.length - 1]}</p>
      </div>
      <div className='self-stretch text-center text-stone-300 text-lg font-normal leading-relaxed'>
        {description}
      </div>
    </div>
  );
};
