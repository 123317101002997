const Linkedin = (props: any) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" fill="#989898" />
    <g id="Home page" clip-path="url(#clip0_0_1)">
      <rect
        width="1440"
        height="9703"
        transform="translate(-1136 -9401)"
        fill="#02060F"
      />
      <g id="Background"></g>
      <g id="Content">
        <g id="Footer">
          <mask id="path-1-inside-1_0_1" fill="white">
            <path d="M-1136 -152H304V300H-1136V-152Z" />
          </mask>
          <path
            d="M-1136 -151H304V-153H-1136V-151Z"
            fill="#00447A"
            mask="url(#path-1-inside-1_0_1)"
          />
          <g id="Frame 82">
            <g id="Navigation">
              <g id="Menu">
                <g id="Linkedin">
                  <g id="Linkedin icon">
                    <path
                      id="Vector"
                      d="M21 8V16C21 18.7614 18.7614 21 16 21H8C5.23858 21 3 18.7614 3 16V8C3 5.23858 5.23858 3 8 3H16C18.7614 3 21 5.23858 21 8Z"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      id="Vector_2"
                      d="M7 17V13.5V10"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      id="Vector_3"
                      d="M11 17V13.75M11 13.75V10M11 13.75C11 10 17 10 17 13.75V17"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      id="Vector_4"
                      d="M7 7.01013L7.01 6.99902"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_0_1">
        <rect
          width="1440"
          height="9703"
          fill="white"
          transform="translate(-1136 -9401)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default Linkedin;
