import React from 'react';

interface IProps {
  label: string;
  startIcon?: React.ReactNode;
  onClick?: () => void;
  variant?: 'primary' | 'secondary' | 'outline';
  size?: 'small' | 'medium' | 'large';
}

export const Button: React.FC<IProps> = ({
  label,
  onClick,
  startIcon,
  variant = 'primary',
  size = 'medium',
}) => {
  const baseStyle = `
     p-4 rounded-xl text-sm flex items-center cursor-pointer transition-colors duration-300 ease-in-out justify-center ${
       variant === 'primary'
         ? ' text-base text-white bg-[#3390DB] py-2 rounded-md'
         : ''
     } ${
    variant === 'secondary'
      ? 'text-lg text-white bg-[#3390DB] w-[20%] text-base font-normal rounded-lg md:w-[100%] '
      : ''
  }
    ${
      variant === 'outline'
        ? ' font-semibold absolute bottom-2 right-2 bg-gray-800 bg-opacity-60 rounded-3xl text-white text-md px-1 flex items-center'
        : ''
    }
    ${size === 'small' ? 'py-1 px-2 text-xs' : ''}
    ${size === 'large' ? 'py-3 px-4 text-base' : ''}
  `;

  return (
    <button
      className={`${baseStyle}`}
      onClick={onClick}
      aria-label={label}
    >
      {startIcon && <span className='pr-2'>{startIcon}</span>}
      {label}
    </button>
  );
};
