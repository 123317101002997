import { useState, useEffect } from "react";

interface WindowDimensions {
  width: number;
}

function getWindowDimensions(): WindowDimensions {
  const { innerWidth: width } = window;
  return { width: Math.min(width, 1500) };
}

export default function useWindowDimensions(): number {
  const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions.width;
}
