import { FC, useState } from 'react';

interface HexagonCardProps {
  text: string;
  height?: number;
  width: number;
  isStrokeWidth?: boolean;
  isFontSize?: boolean;
  isBg?: boolean;
}

export const HexagonCard: FC<HexagonCardProps> = ({
  text,
  height,
  width,
  isStrokeWidth = true,
  isFontSize = true,
  isBg,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className='relative'>
      <div
        className={`${
          isBg
            ? 'flex items-center justify-center w-[140%] h-[140%] bg-gradient-to-br from-sky-700 to-transparent absolute left-[-15%] bottom-[-25%] rounded-full blur-3xl'
            : ''
        }`}
      />

      <svg
        id='hexagon'
        height={height ? height : width}
        width={height ? width / 2 : width}
        viewBox={`0 0 100 100`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className='relative z-10'
      >
        <polygon
          points='50,0 93.3,25 93.3,75 50,100 6.7,75 6.7,25'
          className={`cursor-pointer ${
            isHovered && isStrokeWidth ? 'fill-white' : 'fill-transparent'
          } stroke-stroukBlue ${isStrokeWidth ? 'stroke-[0.6px]' : 'stroke-0'}`}
        />
        {text && (
          <foreignObject
            x='10'
            y='25'
            width='80'
            height='50'
          >
            <div
              className={`flex justify-center items-center w-full h-full text-center z-10 ${
                isHovered && isStrokeWidth ? 'text-sky-500' : 'text-white'
              } font-manrope font-200 leading-30 ${
                isFontSize && isStrokeWidth ? 'text-[10px]' : 'text-[16px]'
              }`}
            >
              {text}
            </div>
          </foreignObject>
        )}
      </svg>
    </div>
  );
};
