import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Binance from "../../assets/Image/CompaniesLogo/Binance.png";
import Coinbase from "../../assets/Image/CompaniesLogo/Coinbase.png";
import Morgan from "../../assets/Image/CompaniesLogo/Morgan.png";
import BlackRock from "../../assets/Image/CompaniesLogo/BlackRock.png";
import Amundi from "../../assets/Image/CompaniesLogo/Amundi.png";
import { CustomImage } from "../CustomImage/CustomImage";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";

interface Company {
  name: string;
  imageUrl: any;
}

export const CompaniesSlider: React.FC = () => {
  const companies: Company[] = [
    { name: "Binance", imageUrl: Binance },
    { name: "Coinbase", imageUrl: Coinbase },
    { name: "Morgan", imageUrl: Morgan },
    { name: "BlackRock", imageUrl: BlackRock },
    { name: "Amundi", imageUrl: Amundi },
  ];

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    autoplay: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
          autoplay: true,
        },
      },
    ],
  };

  const sectionRef = useRef<HTMLDivElement>(null);

  useGSAP(() => {
    if (sectionRef.current) {
      gsap.fromTo(
        ".companies",
        {
          opacity: 0,
          x: -550,
        },
        {
          opacity: 1,
          x: 0,
          duration: 1,
          scrollTrigger: {
            trigger: sectionRef.current,
            start: "top 100%",
            end: "bottom 60%",
            scrub: true,
          },
        }
      );
    }
  }, []);

  return (
    <div ref={sectionRef} className="w-full h-auto companies">
      <div className="justify-start items-start gap-1 inline-flex ">
        <div className="px-28 sm:px-4 text-white text-5xl sm:text-[32px] font-bold leading-10">
          Companies We've Served
        </div>
      </div>
      <Slider {...settings}>
        {companies.map((company, index) => (
          <div
            key={index}
            className="px-8 sm:px-1 py-3.5 justify-center items-center flex"
          >
            <CustomImage
              className="w-56 h-28"
              src={company.imageUrl}
              alt={company.name}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};
