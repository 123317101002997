import React from "react";
import { Main } from "./sections/Main/Main";
import { Header } from "./sections/Header/Header";
import { Footer } from "./sections/Footer/Footer";
import { Hero } from "./components/Hero/Hero";
import videoBG from "./assets/video/_Main_Comp_BG1.mp4";

const App: React.FC = () => {
  return (
    <div className="h-screen w-screen !bg-[#01060f] font-manrope ">
      <div className="h-[100vh] flex flex-col relative overflow-hidden">
        <div className="fixed inset-0">
          <video
            autoPlay
            loop
            muted
            playsInline
            className="w-full h-full object-cover"
          >
            <source src={videoBG} type="video/mp4" />
          </video>
        </div>

        <Header />
        <Hero />
      </div>

      <Main />
      <Footer />
    </div>
  );
};

export default App;
