import React, { useRef, useEffect } from "react";
import { Button } from "../Buttons/Button";
import gsap from "gsap";
import useWindowDimensions from "../../Hooks/ScreenDimensionsHook";

export const Hero: React.FC = () => {
  const headingRef = useRef<HTMLHeadingElement | null>(null);
  const subheadingRef = useRef<HTMLHeadingElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const width = useWindowDimensions();
  const isMobile = width < 1440;
  useEffect(() => {
    gsap.fromTo(
      headingRef.current,
      { y: 50, opacity: 0 },
      { y: 0, opacity: 1, duration: 1 }
    );
    gsap.fromTo(
      subheadingRef.current,
      { y: 50, opacity: 0 },
      { y: 0, opacity: 1, duration: 1, delay: 0.2 }
    );
    gsap.fromTo(
      buttonRef.current,
      { y: 50, opacity: 0 },
      { y: 0, opacity: 1, duration: 1, delay: 0.2 }
    );
  }, []);

  return (
    <div className="">
      <div className="sm:hidden md:hidden w-96 h-96 absolute right-[-220px] z-[0] top-[-240px] bg-gradient-to-br from-darkSky to-darkSky2 rounded-full blur-3xl z-1" />
      <div className="absolute w-full left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] ">
        <div className="flex flex-col justify-center text-center items-center gap-6 ">
          <h1
            ref={headingRef}
            className="text-6xl md:text-4xl font-bold text-white"
          >
            Your Trusted Technology Partner
          </h1>
          <h3
            ref={subheadingRef}
            className="text-lg md:text-sm font-normal text-white max-w-[690px] sm:max-w-80"
          >
            Bridging the gap between tradition and disruption, bringing
            institutions to the forefront of today’s tech landscape one project
            at a time.
          </h3>
          {isMobile ? (
            <span
              className="w-[40%] 
              
            flex justify-center items-center"
              ref={buttonRef}
            >
              <Button label="Get in Touch" variant="secondary" />
            </span>
          ) : (
            <span
              className="3xl:w-[60%] 
              w-[40%]
              text-[16px]
            flex justify-center items-center"
              ref={buttonRef}
            >
              <Button label="Get in Touch" variant="secondary" />
            </span>
          )}
        </div>
      </div>
      <div className="sm:hidden md:hidden w-96 h-96 -z-1 absolute left-[-340px] bottom-[-240px] rotate-[-107.32deg] bg-gradient-to-br from-stroukBlue to-darkSky2 rounded-full blur-3xl" />
    </div>
  );
};
